import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
import * as React from 'react'

interface TooltipProps {
    trigger: JSX.Element
    content: JSX.Element | string
}

const CTooltip = ({ trigger, content }: TooltipProps) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger className="rounded-none px-0">{trigger}</TooltipTrigger>
                <TooltipContent>{content}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}

export default CTooltip
