import React from 'react'

import * as Icons from 'lucide-react'
interface LoaderProps {
    size: number
}
const Loader = React.memo<LoaderProps>(({ size }) => {
    return (
        <div className="motion-safe:animate-spin p-2 flex items-center">
            <Icons.Loader
                viewBox="-3 -3 30 30"
                className="text-primary p-1 flex-1"
                style={{
                    height: size || 20,
                    width: size || 20,
                }}
            />
        </div>
    )
})
export default Loader
