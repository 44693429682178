import * as ui from 'components/ui/input'
import React from 'react'

interface PropsFormTextField extends ui.InputProps {
    onChangeFn: (newTxt: string) => void
    password?: boolean
    error?: string
    endAdornment?: JSX.Element
}

const FormTextInput = React.memo<PropsFormTextField>((props) => {
    const { onChangeFn, password, endAdornment, ...others } = props
    return (
        <>
            <div className="w-full my-3 relative flex items-center">
                <ui.Input
                    onChange={(event) => onChangeFn(event.target.value)}
                    type={password ? 'password' : undefined}
                    {...others}
                />
                <span className="absolute rounded-full right-4">{endAdornment}</span>
            </div>
            {props.error ? <p className="text-xs text-red-600 mt-0.5">{props.error}</p> : null}
        </>
    )
})

export default FormTextInput
