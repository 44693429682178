import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from 'components/ui/dialog'
import * as React from 'react'

const CustomModal = ({
    trigger,
    title,
    description,
    actions,
    forceOpen,
    fullW,
}: {
    trigger: JSX.Element
    title?: string
    description?: string
    actions: JSX.Element
    forceOpen?: boolean
    fullW?: boolean
}) => {
    return (
        <Dialog modal={!!trigger} open={forceOpen}>
            <DialogTrigger className={fullW ? 'flex-1' : ''}>{trigger}</DialogTrigger>
            <DialogContent className="flex flex-col items-center justify-between  w-11/12 md:6/12 lg:4/12 min-h-[200px]">
                <DialogHeader className="mx-auto flex flex-col items-center">
                    <DialogTitle className="text-2xl text-center">{title}</DialogTitle>
                    <DialogDescription className="w-11/12 text-center">{description}</DialogDescription>
                </DialogHeader>
                <div className="w-full ">{actions}</div>
            </DialogContent>
        </Dialog>
    )
}

export default CustomModal
