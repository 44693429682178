import { AuthContext } from 'providers/auth-provider'
import { useFormik } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import * as React from 'react'
import * as TestySDK from 'sdk/testy-web-sdk'
import { PhoneSchema } from 'utils/form/validation-schema'
import Button from 'components/shared/button'
import CustomModal from 'components/shared/custom-Modal'
import FormTextField from 'components/shared/form-field-input'

const NoPhoneModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const { authData } = React.useContext(AuthContext)
    const { t } = useTranslation('common')

    const form = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: PhoneSchema,
        onSubmit: ({ phone }) => {
            TestySDK.updateUser(authData?.userUid || '', undefined, phone).then(() => {
                onClose()
            })
        },
    })

    return (
        <CustomModal
            trigger={<></>}
            forceOpen={open}
            title="Inserisci il tuo numero per usare il simulatore"
            actions={
                <div className="flex flex-col items-center">
                    {' '}
                    <FormTextField
                        className="mt-2"
                        value={form.values.phone}
                        error={form.errors.phone ? t(`${form.errors.phone}`) : ''}
                        onChangeFn={form.handleChange('phone')}
                        placeholder="Inserisci il tuo numero di telefono"
                    />
                    <Button className="my-2 w-full" onClick={() => form.handleSubmit()} role="submit">
                        Salva numero
                    </Button>
                </div>
            }
        />
    )
}

export default NoPhoneModal
