
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import * as React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { SnackbarProvider } from 'notistack';
import type { AppProps } from 'next/app';
import { AuthProvider } from 'providers/auth-provider';
import UserContextProvider from 'providers/context/user-context';
import PageProgress from 'components/shared/page-progress';
import { ModalProvider } from 'react-modal-hook';
/** Global styles */
import 'styles/globals.css';
import AppConfigProvider from 'providers/app-config-provider';
import { analytics } from 'utils/analytics';
import { applyTheme } from 'providers/theme/utils';
// Client-side cache, shared for the whole session of the user in the browser.
function App(props: AppProps) {
    const { pageProps, Component, router } = props;
    const handleRouteChange = React.useCallback(() => {
        analytics.page();
    }, []);
    React.useEffect(() => {
        applyTheme();
    }, []);
    React.useEffect(() => {
        applyTheme();
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => router.events.off('routeChangeComplete', handleRouteChange);
    }, [handleRouteChange, router.events]);
    return (<AuthProvider>
            <AppConfigProvider>
                <UserContextProvider>
                    <SnackbarProvider>
                        <ModalProvider>
                            <PageProgress />
                            <Component {...pageProps}/>
                            <Analytics />
                        </ModalProvider>
                    </SnackbarProvider>
                </UserContextProvider>
            </AppConfigProvider>
        </AuthProvider>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  