import * as React from 'react'
import * as firebaseSdk from 'sdk/firebase'
import * as LocalStorageUtils from 'utils/local-storage'
import * as T from 'components/ui/typography'

import Button from 'components/shared/button'
import { AuthContext } from 'providers/auth-provider'

const LockedPage = () => {
    const { removeUser } = React.useContext(AuthContext)

    const logoutUser = async () => {
        await firebaseSdk.logout()
        LocalStorageUtils.clearStorage()
        removeUser()
    }

    return (
        <div className="flex flex-col items-center gap-3 justify-center h-screen">
            <T.H2>Mi spiace, ma non puoi accedere alla web app!😔</T.H2>
            <Button variant="ghost" onClick={() => logoutUser()}>
                Logout
            </Button>

            <T.P className="text-primary">Contatta il supporto clienti per essere abilitato.</T.P>
        </div>
    )
}

export default LockedPage
