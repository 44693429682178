import * as React from 'react'
import * as T from 'components/ui/typography'
import Image from 'next/image'

import TestyLogo from 'public/icons/logo_testy.svg'

import Link from 'next/link'
import Routes from 'utils/routes'
import { useAppConfig } from 'hooks/features/use-features'

interface LogoProps {
    hideName?: boolean
    disableLink?: boolean
}

const Logo = (props: LogoProps): JSX.Element => {
    const { appConfig } = useAppConfig()

    const appCode = process.env.NEXT_PUBLIC_APP_CODE

    const logoPath = React.useMemo(() => appConfig.image, [appConfig.image])
    const appName = React.useMemo(() => appConfig.name, [appConfig.name])

    if (!props.disableLink)
        return (
            <Link href={Routes.Home}>
                <div className="flex items-center">
                    {appCode === 'testy' ? (
                        <TestyLogo viewBox="0 0 330 330" className="h-[40px] w-[40px]" />
                    ) : (
                        <Image
                            src={logoPath}
                            alt={`${appCode} logo`}
                            style={{
                                borderRadius: 5,
                                border: appCode === 'guidoio' ? 'solid' : 'none',
                            }}
                            width={40}
                            height={40}
                        />
                    )}
                    {props.hideName ? null : <T.H3 className="text-primary pl-2">{appName}</T.H3>}
                </div>
            </Link>
        )
    else
        return (
            <div className="flex items-center">
                {appCode === 'testy' ? (
                    <Image src={TestyLogo} height={40} width={40} alt="testy" />
                ) : (
                    <Image src={logoPath} alt={`${appCode} logo`} style={{ borderRadius: 5 }} width={40} height={40} />
                )}
                {props.hideName ? null : <T.H3 className="text-primary pl-2">{appName}</T.H3>}
            </div>
        )
}

export default Logo
