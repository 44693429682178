import * as React from 'react'
import * as TestySDK from 'sdk/testy-web-sdk'
import * as firebaseSdk from 'sdk/firebase'
import { getFromStorage, saveToStorage } from 'utils/local-storage'
import { UserBasicData } from 'providers//context/user-context'
import router from 'next/router'
import Routes, { PublicRoutes } from 'utils/routes'
import AppLoader from 'components/shared/app-loader'

interface AuthContextProviderProps {
    children: JSX.Element | JSX.Element[]
}

type UserAuth = {
    userUid: string | null
    token: string | null
    logged: boolean
    expire_at: number | null
}
interface AuthContext {
    authData: UserAuth | undefined
    setUser: (val: UserBasicData) => void
    removeUser: () => void
}

export const AuthContext = React.createContext<AuthContext>({
    authData: { userUid: null, token: null, expire_at: null, logged: false },
    setUser: (val) => console.log(val),
    removeUser: () => console.log('removed'),
})

export const AuthProvider = ({ children }: AuthContextProviderProps): JSX.Element => {
    const [loading, setLoading] = React.useState<boolean>(true)
    const [authData, setAuthData] = React.useState<UserAuth>()

    // save user function
    const setUserInStorage = (val: UserBasicData) => {
        saveToStorage('userId', val.uid)
        saveToStorage('token', val.token)
        setAuthData(getAuthData())
    }

    const removeUser = () => {
        TestySDK.removeAuthToken()
        setAuthData(getAuthData())
    }

    const getAuthData = React.useCallback(() => {
        const userUid = getFromStorage('userId')
        const token = getFromStorage('token')
        const expire_at = getFromStorage('tokenExp')
        return {
            userUid: userUid,
            token: token,
            expire_at: expire_at,
            logged: !!userUid && !!token && !!expire_at,
        }
    }, [])

    React.useEffect(() => {
        const userUid = getFromStorage('userId')
        const token = getFromStorage('token')
        const expire_at = getFromStorage('tokenExp')
        if (expire_at < Date.now() && userUid && expire_at !== null) {
            firebaseSdk.RefreshFbToken()
            setAuthData(getAuthData())
        } else TestySDK.setAuthToken(token)
        setLoading(false)
    }, [getAuthData])

    React.useEffect(() => {
        setAuthData(getAuthData())
    }, [getAuthData])

    React.useEffect(() => {
        if (authData) {
            if (!authData.logged && !PublicRoutes.includes(router.pathname)) {
                router.push({ pathname: Routes.Login })
            }
        }
    }, [authData, authData?.logged])

    if (loading) return <AppLoader />

    return (
        <AuthContext.Provider value={{ authData, setUser: setUserInStorage, removeUser }}>
            {children}
        </AuthContext.Provider>
    )
}
