import { AppEnvKey } from 'providers/app-config-provider'

import appConfig from 'utils/config/app.config.json'

import basePalette from './base'

export interface SimplePaletteColorOptions {
    light?: string
    main: string
    dark?: string
    contrastText?: string
}

export interface ITheme {
    [key: string]: SimplePaletteColorOptions
}

export interface IThemes {
    [key: string]: ITheme
}

export interface IMappedTheme {
    [key: string]: string | null
}

const palette = {
    ...basePalette,
    ['primary']: { ...appConfig[process.env.NEXT_PUBLIC_APP_CODE as AppEnvKey].colorPalette },
}

function hexToHSL(hex: string): string | null {
    hex = hex.replace(/#/g, '')
    if (hex.length === 3) {
        hex = hex
            .split('')
            .map(function (hex) {
                return hex + hex
            })
            .join('')
    }
    const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(hex)
    if (!result) {
        return null
    }
    let r = parseInt(result[1], 16)
    let g = parseInt(result[2], 16)
    let b = parseInt(result[3], 16)
    // eslint-disable-next-line no-unused-expressions
    ;(r /= 255), (g /= 255), (b /= 255)
    const max = Math.max(r, g, b),
        min = Math.min(r, g, b)
    let h,
        s,
        l = (max + min) / 2
    if (max == min) {
        h = s = 0
    } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
        }
        if (h) h /= 6
    }
    s = s * 100
    s = Math.round(s)
    l = l * 100
    l = Math.round(l)
    h = Math.round(360 * (h || 0))

    return '' + h + ' ' + s + '% ' + l + '%'
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
    const primaryHSL = hexToHSL(variables.primary.main)
    const secondaryHSL = hexToHSL(variables.primary.contrastText || '')
    const accentHSL = hexToHSL(variables.primary.light || '')
    const backgroundHSL = hexToHSL('#ffffff' || '')
    const borderHSL = hexToHSL(variables.grey.light || '')
    const successHSL = hexToHSL(variables.success.main || '')
    const errorHSL = hexToHSL(variables.error.main || '')
    const warningHSL = hexToHSL(variables.warning.main || '')

    console.log(variables)

    return {
        '--primary': primaryHSL,
        '--secondary': secondaryHSL,
        '--success': successHSL,
        '--error': errorHSL,
        '--color-text-primary': variables.primary.contrastText || '',
        '--background': backgroundHSL,
        '--popover': backgroundHSL,
        '--accent': accentHSL,
        '--border': borderHSL,
        '--warning': warningHSL,
    }
}

export const applyTheme = async () => {
    const themeObject: IMappedTheme = mapTheme(palette)
    if (!themeObject) return
    console.log(themeObject)

    if (typeof document !== 'undefined') {
        const root = document.documentElement

        Object.keys(themeObject).forEach((property) => {
            if (property === 'name') {
                return
            }

            root.style.setProperty(property, themeObject[property])
        })
    }
}
