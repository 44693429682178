import * as React from 'react'

import AppLogo from 'components/shared/logo'
import Loader from 'components/shared/loader'

export default function AppLoader() {
    return (
        <div className="flex flex-col h-screen items-center gap-6 justify-center">
            <AppLogo hideName />
            <Loader size={60} />
        </div>
    )
}
