import * as React from 'react'

import * as Ui from 'components/ui/button'

import CTooltip from 'components/shared/tooltip'

export interface CustomButtonProps extends Ui.ButtonProps {
    children: string | JSX.Element | JSX.Element[]
    loading?: boolean
    tooltip?: string
}

const Button = ({ children, tooltip, className, ...rest }: CustomButtonProps) => {
    const BaseButton = (
        <Ui.Button className={` normal-case font-[Manrope] ${className}`} {...rest}>
            {children}
        </Ui.Button>
    )
    return tooltip ? <CTooltip trigger={<span>{BaseButton}</span>} content={tooltip} /> : BaseButton
}

export default Button
