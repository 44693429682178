import * as React from 'react'
import { cn } from 'lib/utils'

interface Typo {
    children: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> | string | string[]
    className?: string
    style?: React.CSSProperties
}

export function H1({ children, className, style }: Typo) {
    return (
        <h1
            style={style}
            className={cn('scroll-m-20 font-[Manrope] text-4xl font-extrabold tracking-tight lg:text-5xl', className)}>
            {children}
        </h1>
    )
}

export function H2({ children, className, style }: Typo) {
    return (
        <h2
            style={style}
            className={cn(
                `scroll-m-20  pb-2 text-3xl font-[Manrope] font-semibold tracking-tight transition-colors first:mt-0`,
                className,
            )}>
            {children}
        </h2>
    )
}

export function H3({ children, className, style }: Typo) {
    return (
        <h3 style={style} className={cn(`scroll-m-20 font-[Manrope] text-2xl font-semibold tracking-tight`, className)}>
            {children}
        </h3>
    )
}

export function P({ children, className, style }: Typo) {
    return (
        <p style={style} className={cn(`leading-7  `, className)}>
            {children}
        </p>
    )
}

export function Blockquote({ children, className, style }: Typo) {
    return (
        <blockquote style={style} className={cn(`mt-6 border-l-2 pl-6 italic`, className)}>
            {children}
        </blockquote>
    )
}
export function Large({ children, className, style }: Typo) {
    return (
        <div style={style} className={cn(`text-lg font-semibold `, className)}>
            {children}
        </div>
    )
}
export function Small({ children, className, style }: Typo) {
    return (
        <small style={style} className={cn(`text-sm font-medium leading-none `, className)}>
            {children}
        </small>
    )
}
export function Muted({ children, className, style }: Typo) {
    return (
        <p style={style} className={cn(`text-sm text-muted-foreground `, className)}>
            {children}
        </p>
    )
}
